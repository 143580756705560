@import 'src/resources/scss/utilities/variables';
@import 'src/resources/scss/utilities/mixins';

#mitSundhedPlus {
  height: calc(var(--vh, 1vh) * 100);

  .mitSundhed-section {
    padding: 40px;
    background-color: $lightest-grey;
    min-height: calc(100% - 56.63px); // 56.63px is the height of the header
    margin-bottom: 40px;

    @media only screen and (max-width: 800px) {
      padding: 20px;
      margin-bottom: 0;
    }

    .custom-card .card-body {
      padding: 20px 25px;
    }

    .sundhedCreditInfo {
      display: flex;
      max-width: 1100px;
    }

    .entity-information-card {
      width: 100%;

      .header-information {
        display: flex;

        justify-content: space-between;

        .header-text {
          .card-title {
            line-height: 48px !important;
            font-weight: 700;
            color: #444444;
            margin-bottom: 20px;
          }
        }

        .center {
          align-items: center !important;
          flex-flow: column !important;
          left: 50%;
        }

        .menu-toggler {
          display: flex;
          outline: none;
          justify-content: flex-end;
          margin-top: 10px;
          width: auto !important;
        }
      }

      @media only screen and (max-width: 679px) {

        /* Adjust breakpoint as needed */
        .header-information {
          flex-direction: column;
          align-items: center;
          /* Center card title horizontally */
        }

        .card-title.center {
          margin: 0 auto;
          /* Remove default margins for centering */
          font-size: 32px !important;
        }

        .btn.btn-white.menu-toggler {
          // set this item on the right side of the card
          margin-left: auto;
          margin-top: -50px;
          margin-bottom: 20px;
        }
      }

      .warning-card {
        display: flex;

        border-radius: 4px;
        padding: 20px;

        &.yellow {
          background-color: #fff9e6;
          border: 1px solid #ffd570;
        }

        &.green {
          width: 100%;
          background-color: #f1f7f3;
          border: 1px solid #72ae8c;
        }

        margin-bottom: 20px;

        .warning-text {
          display: flex;
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: #444444;
          align-items: center;
          justify-content: space-between;

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 16px;
            line-height: 1.5;
          }

          p {
            margin-right: 60px;
            margin-bottom: 0;
          }
        }

        a {
          min-width: 218px;
        }

        .btn-renew-card {
          width: 100%;
          height: 40px;

          text-transform: uppercase;
          font-size: 14px;
        }

        @media screen and (max-width: 680px) {

          // add the button down below the text on the left side
          .warning-text {
            flex-direction: column;

            display: block;

            a {
              .btn-renew-card {
                margin-top: 25px;
                max-width: 218px;
              }
            }
          }
        }

        .warning-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          border-radius: 50%;

          .infoIcon {
            margin-bottom: 1px;
            width: 20px;
            height: 20px;
            fill: #996b68;
          }
        }
      }
    }

    & .loading-message {
      background-color: $lightest-grey;
      z-index: 10;
    }

    .data-cards .card {
      border: 0;

      & .card-body {
        padding: 0;
      }
    }

    .credit-info-card {
      .title {
        font-size: 18px;
        font-family: 'Maven Pro Medium';
        font-weight: 500;
        line-height: 27px;
        color: #444444;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;

        @media screen and (min-width: 768px) and (max-width: 980px) {
          font-size: 16px;
        }
      }

      .button-actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .btn-credit-info {
          max-width: 216px;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;

          .lnil {
            font-size: 17px;
          }
        }
      }

      .renew-creditCard {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn-renew-card-button {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          line-height: 17.72px;
          width: 236px;
          height: 48px;
          padding: 15px 30px;
        }
      }
    }

    & .data-cards {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 680px) {
        .card-deck {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5rem;
          justify-content: space-between;

          .card:nth-child(1) {
            grid-column: 1 / -1;
          }

          .card:nth-child(2) {
            grid-column: 1 / -1;
          }

          .card:nth-child(3),
          .card:nth-child(4) {
            grid-column: span 1;
          }
        }
      }

      @media screen and (min-width: 681px) and (max-width: 1220px) {
        & .card-deck {
          display: grid;
          grid-template-columns: repeat(auto-fill, 48%);
          grid-gap: 1rem;
          justify-content: space-between;
        }
      }

      @media screen and (min-width: 1221px) {
        .card-deck {
          grid-template-columns: 320px repeat(auto-fill, minmax(0, 150px));
          grid-gap: 20px;

          margin: 0 !important;
          width: 100%;

          .status-card-information {
            min-width: 320px;
          }

          .card {
            flex: 1 1 auto !important;
            margin: 0 !important;
            max-width: 100%;
          }
        }
      }

      .card-deck {
        &>.card {
          flex: 0 0 auto;
        }
      }

      & .card {
        padding: 10px 20px;
        color: $darker-grey;
        background-color: #faf8f6;

        @include media-breakpoint-up(lg) {
          min-width: 126px;
          margin-right: 10px;
          margin-left: 10px;
        }

        @include media-breakpoint-down(lg) {
          margin-bottom: 10px;
        }

        @media only screen and (max-width: 440px) {
          padding: 10px 13px;
        }

        & .data-value {
          font-size: 22px !important;
          font-family: 'Maven Pro Medium';
          line-height: 28px;
          font-weight: 500;
          color: #444444;

          .expiration-info {
            font-size: 14px;
            font-family: 'Maven Pro Regular';
            font-weight: 400;
            line-height: 21px;
            color: #444444;
          }

          &.red {
            color: #df989d;
          }

          &.green {
            color: #72ae8c;
          }

          &.yellow {
            color: #f3ac00;
          }

          &.grey {
            color: #969696;
          }

          &.suspended {
            // add text decoration line tine through
            text-decoration-thickness: 2px !important;

            text-decoration: line-through;

            text-decoration-color: #be323c;
          }

          &.amountZero {
            color: #be323c;
          }
        }

        & .data-label {
          font-size: 14px;
          color: #969696;
          line-height: 21px;
          margin-bottom: 10px;

          @media screen and (min-width: 768px) and (max-width: 980px) {
            font-size: 16px;
          }
        }

        &.data-amount {
          min-width: 175px;
          width: auto;

          @include media-breakpoint-up(lg) {
            min-width: 215px;
            width: auto;
          }

          @media only screen and (max-width: 480px) {
            min-width: 105px;
            width: auto;
          }
        }

        @include media-breakpoint-down(lg) {
          .data-value {
            font-size: 20px;
          }
        }
      }
    }

    .entityTableSection {
      color: #444444;
      max-width: 1100px;

      .table-cell-div:last-of-type {
        position: absolute;
        right: 40px;
      }
    }

    .active-payment-agreement-section,
    .agreement,
    .practitioner-access-section,
    .services-section {
      max-width: 1100px;

      .table-header,
      #list-group-item {
        grid-template-columns: 20% 20% 15% 20% !important;
        padding: 0 10px !important;

        .KebabMenu_Component {

          // background: none;
          // border: none;
          .menu-container {
            .menu-items {
              padding: 15px 5px !important;
              width: 192px;
            }

            .KebabMenu-item {
              padding: 10px 15px;

              &.last {
                border-top: 1px solid #f6f0ee;
              }

              .icon {
                color: #000000;
                font-size: 14px !important;
              }

              &:hover {
                color: $sundhedplus-brawn;

                svg {
                  fill: $sundhedplus-brawn;
                }
              }
            }
          }
        }
      }

      #practitioner-list-item {
        padding: 0 10px !important;
      }

      #billing-list-item {
        padding: 0 10px !important;
      }

      // .table-body {
      //   min-width: 0;
      // }
    }

    @media only screen and (max-width: 800px) {
      .active-payment-agreement-section {

        .table-header,
        #list-group-item,
        .table-row-div {
          // Hide the columns you don't want to display

          .table-cell-div:nth-child(3),
          .table-cell-div:nth-child(4),
          .table-cell-div:nth-child(5) {
            display: none !important;
          }

          grid-template-columns: 40% 40% 20% !important;
        }

        .table-body {
          min-width: 0;
        }
      }

      .billing-history-section {
        // remove the table-header and have the columns display as a list

        $start: 0;
        $end: 5;

        @for $i from $start through $end {
          .invoices-list-#{$i} {
            height: #{$i * 100+3}px !important;
          }
        }

        .table-header {
          display: none !important;
        }

        .table-body {
          min-width: 0;
        }
      }
    }

    .tableInformation {
      position: relative;
      padding: 0 25px;

      .empty-table {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px !important;
        font-size: 14px;
        font-weight: 400;
        color: #969696;
      }

      @media only screen and (max-width: 414px) {
        padding: 0 16px;
      }

      &.practitioner-access-card {
        padding: 0 !important;
        border-bottom: 0 !important;
        margin-bottom: 0 !important;
        width: 100%;

        .table-row-div {
          grid-template-columns: 2fr 5fr !important;
        }

        .table-header {
          .table-row-div {
            grid-template-columns: 2fr 5fr !important;
          }
        }

        @media only screen and (max-width: 800px) {
          .table-row-div {
            grid-template-columns: 2fr 3fr !important;
          }

          .table-header {
            .table-row-div {
              grid-template-columns: 2fr 3fr !important;
            }
          }
        }

        @media only screen and (max-width: 690px) {

          .custom-table .table-header,
          .custom-table .table-separator,
          .custom-table .table-body {
            min-width: 0;
            width: 100%;
          }
        }
      }

      .title {
        font-size: 18px;
        font-family: 'Maven Pro Medium';
        font-weight: 500;
        line-height: 27px;
        color: #444444;
      }

      .table-header {
        font-family: 'Maven Pro Medium';
        font-weight: 500;
        line-height: 21px;
        background-color: #f6f0ee !important;
        color: #444444;
        min-width: 0;

        .table-row-div {
          grid-template-columns: 20% 20% 15% 20% 15% 10%;
        }

        .billing-history-list {
          grid-template-columns: 20% 37% 20% 25% !important;
        }
      }

      #list-group-item {
        font-family: 'Maven Pro Regular' !important;
        font-weight: 400;
        min-width: 300px;
        grid-template-columns: 20% 20% 15% 20% 15% 10% !important;

        @media only screen and (max-width: 800px) {
          grid-template-columns: 40% 40% 20% !important;
        }

        .actions-menu {
          background-color: #faf8f6;
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;

          .lnil {
            font-size: 20px;
            color: #996b68;
          }
        }
      }

      #billing-list-item {
        font-family: 'Maven pro Regular' !important;
        font-weight: 400;
        grid-template-columns: 20% 37% 20% 23% !important;
        min-width: 300px;
        margin-right: 2px;

        .table-cell-div:nth-child(3) {
          font-family: 'Maven Pro Medium';
          font-weight: 500;
        }

        .table-cell-div:last-of-type {
          position: relative !important;
          right: 0 !important;
          justify-content: flex-end;
          text-align: right;
        }

        @media only screen and (max-width: 800px) {
          background-color: #faf8f6 !important;
          margin: 10px 15px 10px 0;
          padding-right: 10px;
          display: grid;
          grid-template-columns: auto auto !important;
          grid-template-rows: repeat(2, 1fr) !important;
          padding: 10px;

          .table-cell-div {
            height: 36px;
          }

          // display the first column as a row
          .table-cell-div:nth-child(1) {
            grid-column: 1 / 1;
            grid-row: 1;
            justify-content: flex-start;
            text-align: left;
            color: #969696;
          }

          // display the second column as a row
          .table-cell-div:nth-child(2) {
            grid-column: 1 / 1;
            grid-row: 2;
            justify-content: flex-start;
            text-align: left;
            font-family: 'Maven Pro Medium';
            font-weight: 500;
          }

          // display the third column as a row
          .table-cell-div:nth-child(3) {
            grid-column: 2 / -1;
            grid-row: 1;
            justify-content: flex-end;
            text-align: right;
            font-family: 'Maven Pro Medium';
            font-weight: 400;
          }

          // display the fourth column as a row
          .table-cell-div:last-of-type {
            position: relative !important;
            right: 0 !important;
            grid-column: 2 / -1;
            grid-row: 2;
            font-family: 'Maven Pro Medium';
            font-weight: 500;
            justify-content: flex-end;
            text-align: right;
          }
        }

        .status-approved {
          color: #72ae8c;
        }

        .status-pending {
          color: #edbd45;
        }

        .status-payed {
          color: $green-main;
        }

        .status-declined {
          color: #a6a6a6;
        }

        .actions-menu {
          .delete-clinic {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 160px;
            height: 31px;
            padding: 5px 0 !important;

            font-size: 14px;

            .lnil {
              color: #996b68;
              font-size: 17px;
            }
          }
        }

        &.clickable {
          cursor: pointer !important;
        }
      }
    }

    .total-outstanding-debt {
      width: fit-content !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #faf8f6;
      max-width: 269px;
      height: 41px;
      margin-bottom: 30px;

      .label {
        font-size: 14px;
        font-weight: 400;

        color: #969696;
        margin-right: 10px;
      }

      .remaining-amount {
        font-family: 'Maven Pro Medium';
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        i {
          color: #996b68;
          margin-right: 10px;
        }
      }
    }

    .payment-details-section {
      color: #444444;
      max-width: 1100px;

      .budget-entry {
        height: 35px;

        @media only screen and (max-width: 800px) {
          height: 41px;
          padding: 10px;
        }

        .right {
          &.status-green {
            color: #72ae8c;
          }

          &.status-yellow {
            color: #f3ac00;
          }

          &.status-red {
            color: #be323c;
          }
        }

        .creditCard {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            a {
              text-decoration: none !important;
            }
          }

          .card-info {
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: center;
            min-width: 135px;
            height: 26px;
            border: 1px solid #f6f0ee;
            background-color: #ffffff;
            border-radius: 30px !important;
            padding: 4px 14px;
            color: #996b68;
            margin-right: 20px;

            &:hover {
              cursor: pointer;
              background-color: #f6f0ee;
              text-decoration: none !important;

              a {
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }

    .documentation-section {
      color: #444444;
      max-width: 1100px;

      @media only screen and (max-width: 400px) {
        margin-bottom: 60px;
      }

      .content {
        cursor: pointer;

        li {
          text-decoration: underline;
        }

        .documentation-modal {
          height: 370px;
          border-radius: 4px;
          background-color: $lightest-grey;
          color: $darker-grey;
          font-size: 14px;
          font-weight: 300;
          overflow-x: auto;
          margin-top: 45px;
          margin-bottom: 30px;

          @media only screen and (max-width: 500px) {
            height: 350px;
            margin-bottom: env(safe-area-inset-bottom) !important;
            padding-bottom: env(safe-area-inset-bottom) !important;
          }
        }
      }
    }

    .member-portal-profile {
      max-width: 1100px;

      @media only screen and (max-width: 600px) {
        .col-12:last-child {
          padding-left: 15px !important;
        }
      }

      .media-left {
        padding-top: 0 !important;
      }
    }
  }

  .services-action {
    border: 1px solid #F6F0EE;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .left-side {
      max-width: 570px;
    }

    h6 {
      color: #444;
      font-size: 18px;
      line-height: 27px;
      font-family: $font-family-medium;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      margin-top: 15px;
      margin-bottom: 0;
    }

    a {
      color: #be323c;
      cursor: pointer;
    }

    button {
      text-transform: uppercase;
      width: 218px;
      font-size: 14px;
      line-height: 16px;
    }

    .service-fee {
      margin-top: 5px;
      color: #969696;
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      @media screen and (min-width: 768px) and (max-width: 980px) {
        font-size: 16px;
      }
    }

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      padding: 10px;

      .left-side,
      .right-side {
        width: 100%;
      }

      button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .loan-details-menu {
    line-height: 100%;

    .icon {
      font-size: 18px;
      color: #000;
    }
  }
}

.error-content {
  max-width: 480px !important;
  padding: 20px 40px !important;

  h1 {
    font-size: 32px;
    font-weight: 700;
    color: #444444;
  }

  p {
    padding-right: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
  }

  .button-container {
    display: flex;
    justify-content: center;

    .button-client-portal {
      width: 144px;
      height: 54px;
      font-size: 16px;
      text-transform: uppercase;
    }

    .error-button-client-portal {
      width: 294px;
      height: 54px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}