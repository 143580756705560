/* DEFAULT MODAL STYLES */
.modal-content {
  .modal-body {
    padding: 30px 30px 40px 30px;
  }

  .btn {
    border-radius: 20px;
    padding: 0.75rem 1.25rem;
    min-width: 170px;

    @include focus {
      box-shadow: none !important;
      border: none !important;
    }

    @include font-style(14px, 400, 14px);
  }
}

.acceptance-iframe-modal {
  padding: 0 !important;
  margin: 0 !important;

  .modal-body {
    padding: 0 !important;
    margin: 0 !important;
  }
}

/* WARNING MODAL */
.warning-modal {
  .modal-content {
    text-align: center;
  }

  .icon {
    margin-bottom: 10px;

    i {
      font-size: 70px;
      color: $vivid-orange;
    }
  }

  .headline {
    font-family: $font-family-medium;
  }

  .warning-message {
    margin: 20px auto;
  }
}

.retry-withdrawal-modal,
.application-request--modal {
  .modal-body {
    padding: 0;
  }

  .modal-content {
    text-align: center;
    min-width: 620px !important;
    min-height: 393px !important;
    max-height: 393px !important;

    .loading {
      padding: 60px 40px;
    }

    .error,
    .success {
      padding: 40px 60px 50px 60px;
    }

    .bottom-section {
      margin-top: 50px;
    }
  }

  .loading,
  .modal-content {
    max-height: 433px !important;
  }

  .headline {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  .waiting-text {
    font-size: 22px;
    line-height: 28px;
    margin-top: 30px;
    font-weight: 500;
    font-family: 'Maven Pro Medium';
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;

    font-weight: 400;
    text-align: center;
  }

  .icon {
    margin-bottom: 20px;

    i {
      font-size: 60px;
      color: $sundhedplus-red-main;
    }

    .info-circle {
      font-size: 60px;
      width: 60px;
      height: 60px;
    }
  }

  .headline {
    font-family: $font-family-medium;
  }

  .warning-message {
    margin: 20px auto;
  }
}

.application-request--modal .modal-content {
  height: initial !important;

  .bottom-section {
    margin-top: 30px;
  }
}

.partial-redemption-modal {
  .modal-content {
    max-width: 620px;
    max-height: 580px;
    padding: 0 !important;
    min-height: 1px !important;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
      max-height: 580px !important;
    }

    .btn {
      border-radius: 20px;
      padding: 0.75rem 1.25rem;
      min-width: 170px;

      @include focus {
        box-shadow: none !important;
        border: none !important;
      }

      @include font-style(14px, 400, 14px);
    }
  }

  .partial-redemption-styles {
    #acceptance-flow {
      min-height: 430px;
      overflow: hidden !important;

      .modal-content {
        min-height: 1px !important;
      }
    }
  }
}

.partial-redemption-modal.restructure {
  .modal-content {
    min-height: auto !important;
    max-height: unset !important;

    .modal-body {
      max-height: unset !important;
    }
  }
}

/* NOTIFICATION MODAL */
.notification-modal {
  .modal-body {
    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
  }

  .icon {
    margin-bottom: 10px;

    i {
      font-size: 60px;
      color: $green-main;
    }
  }

  a {
    color: $green-main;
    text-decoration: none;
    cursor: pointer;
  }

  .receipt {
    i {
      font-size: 18px;
      color: $green-main;
    }
  }

  .headline {
    font-family: $font-family-bold;
  }

  .notification-message {
    margin: 20px auto;
  }
}

#Sundhedplus {
  .notification-modal {

    .icon,
    .receipt {
      i {
        color: $sundhedplus-red-main;
      }
    }

    &.warning {
      .icon {
        i {
          color: $sundhedplus-orange-main;
        }
      }
    }

    a {
      color: $sundhedplus-red-main;
    }

    .countdown {
      color: $sundhedplus-red-main;
      border: none;
      background: none;
      cursor: initial;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    &.warning.payment-flow {
      @media (min-width: 516px) {
        &.modal-dialog {
          max-width: 620px;
        }
      }

      .headline {
        font-family: "Maven Pro Medium";
        font-size: 26px;
      }

      .warning-icon {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        fill: $vivid-orange;

        path {
          fill: $vivid-orange;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

/* SERVER ERROR MODAL */
.server-error-modal {
  min-width: 620px;
  padding-bottom: 10px;

  .card-body {
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    width: 97%;
    min-width: auto;
    margin: 0 auto;
  }
}

/* REGISTER INVOICE MODAL */
.new-invoice-Modal {
  .modal-content .modal-body {
    padding: 40px;
  }
}

/* ACTIVATING CREDIT APPLICATION MODAL */
.activatingCreditApplicationModal {
  max-width: 620px;
  width: 100% !important;

  .modal-content .modal-body {
    padding: 40px 60px;

    @media only screen and (max-width: 600px) {
      padding: 20px 30px;
    }
  }
}

/* ADD LOCATION MODAL*/
.new-location-modal {
  .modal-content .modal-body {
    padding: 30px 30px 10px 30px;
  }
}

/* CACHE BUSTING MODAL*/
.cache-busting-modal {
  flex-direction: column;
  text-align: center;

  .subtitle {
    line-height: 30px;
    font-size: 18px;
  }

  .reload-browser-icon {
    padding: 20px;
  }

  svg {
    width: auto;
    height: 80px;
    fill: $sundhedplus-red-main;
  }
}